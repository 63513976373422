import React, { useContext, useState, useEffect } from "react";
import NavbarWithCTAButton from "../components/Nav";
import {
  Card,
  Spinner,
  Table,
  Button,
  Label,
  TextInput,
  Modal,
  Dropdown,
  Pagination,
  Select,
} from "flowbite-react";
import { Store } from "../context/store";
import SideBar from "../components/Sidebar";
import { IoMdArrowBack, IoMdAddCircleOutline, IoMdSend } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
import apiRequest from "../utils/apiRequest";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { FiFilter } from "react-icons/fi";
import { AiOutlineClear } from "react-icons/ai";

function Vouchers() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [voucherArray, setVoucherArray] = store.voucherArray;
  let [loading, setLoading] = useState(false);
  const [secondCard, setSecondCard] = useState(false);
  const [invoiceBody, setInvoiceBody] = useState({});
  const navigate = useNavigate();
  let [msg, setMsg] = useState("");
  const [openModalOut, setOpenModalOut] = useState(false);
  let [vouchers, setVouchers] = useState([]);
  let [expenditures, setExpenditures] = useState([]);
  let [loading1] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(1);
  let [status, setStatus] = useState("pending");
  const page = parseInt(searchParams.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(page);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("");
  let [singleVoucher, setSingleVoucher] = useState({});
  let [loading2, setLoading2] = useState(false);

  useEffect(() => {
    Promise.all([loadVouchers()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [page, status]);

  useEffect(() => {
    Promise.all([loadExpenditures()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  function onCloseModal() {
    setOpenModal(false);
  }

  const onPageChange = (page) => {
    setSearchParams({ page: page });
    setCurrentPage(page);
  };

  const loadVouchers = async () => {
    setLoading(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/vouchers?status=${status}&page=${page}`;
      const { data } = await apiRequest(url, "GET", null, token);
      setVouchers(data.vouchers);
      setTotalPages(data.totalPages);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const loadExpenditures = async () => {
    setLoading(true);
    try {
      let token = localStorage.getItem("royal-token");
      const url = `${baseUrl}/approved/expenditures`;
      const { data } = await apiRequest(url, "GET", null, token);
      setExpenditures(data);
    } catch (error) {
      const unauthorizedErrors = [
        "Access Denied: Invalid or expired token. Please login again",
        "Access Denied: Please log in.",
        "Access Denied: Invalid Token.",
        "Access Denied: Invalid or expired token.",
        "Access Denied: Invalid or Expired Token.",
        "Access Denied: No token provided.",
      ];
      if (unauthorizedErrors.includes(error.message)) {
        setMsg("Session Expired!");
        setOpenModalOut(true);
        localStorage.removeItem("royal-token");
      } else {
        console.log(error);
      }
    }
  };

  const pushToInvoice = (e) => {
    const validations = [
      { condition: !e.expenditureId, message: "Please select an expenditure!" },
      { condition: !e.payee, message: "Please enter a payee!" },
      { condition: !e.amountProcessed, message: "Please enter an amount!" },
      { condition: !e.paymentType, message: "Please select a payment type!" },
    ];

    for (const validation of validations) {
      if (validation.condition) {
        setError(validation.message);
        return false;
      }
    }
    voucherArray.rows.push(e);
    setError("");
    setInvoiceBody({
      expenditureId: "",
      payee: "",
      amountProcessed: "",
      paymentType: "",
      bank: "",
      accountNumber: "",
    });
  };

  const createVoucher = async () => {
    if (voucherArray.rows.length === 0) {
      setError("At least one expenditure must be added to the list.");
      return;
    }
    let body = { item: voucherArray.rows };
    const confirmed = window.confirm(
      "Are you sure you want to create a Voucher Request?"
    );
    if (confirmed) {
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/voucher`;
        let { message } = await apiRequest(url, "POST", body, token);
        setError(message);
        setVoucherArray({ rows: [] });
        setInvoiceBody({});
        loadVouchers();
        loadExpenditures();
        setLoading(false);
      } catch (error) {
        setError(error.message);
        console.log(error.message);
      }
    }
  };

  let displayOnModal = (e) => {
    setSingleVoucher(e);
    setOpenModal(true);
  };

  let logOut = () => {
    navigate("/");
  };

  const changeVoucherStatus = async (a, b) => {
    const confirmed = window.confirm(
      `Are you sure you want to ${b === "cancelled" ? "Cancel" : "Approve"}?`
    );
    if (confirmed) {
      setLoading2(true);
      try {
        let token = localStorage.getItem("royal-token");
        const url = `${baseUrl}/authorize/voucher/${a}`;
        const { data, message } = await apiRequest(
          url,
          "PUT",
          { status: b },
          token
        );
        alert(message);
        setSingleVoucher(data);
        setVouchers((prevMessages) =>
          prevMessages.map((singleVoucher) =>
            singleVoucher.id === data.id ? data : singleVoucher
          )
        );
        setLoading2(false);
      } catch (error) {
        alert(error.message);
        setLoading2(false);
        console.log(error);
      }
    }
  };

  return (
    <>
      <title>Vouchers</title>
      <meta
        name="description"
        content="Vouchers Page to the Royalmines Property Office"
      />
      <div className="container mx-auto">
        <NavbarWithCTAButton />
        {/* {loading && (
          <div className="flex justify-center items-center h-[80vh]">
            <Spinner color="info" aria-label="info spinner example" size="xl" />
          </div>
        )} */}

        {/* {!loading && ( */}
          <div className="container-fluid mx-auto">
            <div className="grid gap-4 grid-cols-6 md:grid-cols-5 mt-5">
              <SideBar vouchers="activess" expenditure="actives" />
              {!secondCard && (
                <div className="col-span-5 md:col-span-4" data-aos="flip-up">
                  <div className="mb-4">
                    <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                      Vouchers
                    </h5>
                    <div className="flex flex-wrap gap-2 mt-5 justify-end">
                      <Button
                        color="success"
                        onClick={() => setSecondCard(true)}
                      >
                        <IoMdAddCircleOutline className="me-2" />
                        New Vouchers
                      </Button>

                      <Dropdown
                        label={
                          <Button className="bg-#6eb5ff">
                            <FiFilter className="me-2" /> Filter By
                          </Button>
                        }
                        dismissOnClick={true}
                        inline
                      >
                        <Dropdown.Item
                          className={status === "" ? "active-dropdown" : ""}
                          onClick={() => {
                            setSearchParams({ page: 1 });
                            setStatus("");
                            setCurrentPage(1);
                          }}
                        >
                          All
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={
                            status === "pending" ? "active-dropdown" : ""
                          }
                          onClick={() => {
                            setSearchParams({ page: 1 });
                            setStatus("pending");
                            setCurrentPage(1);
                          }}
                        >
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={
                            status === "approved" ? "active-dropdown" : ""
                          }
                          onClick={() => {
                            setSearchParams({ page: 1 });
                            setStatus("approved");
                            setCurrentPage(1);
                          }}
                        >
                          Approved
                        </Dropdown.Item>
                        <Dropdown.Item
                          className={
                            status === "cancelled" ? "active-dropdown" : ""
                          }
                          onClick={() => {
                            setSearchParams({ page: 1 });
                            setStatus("cancelled");
                            setCurrentPage(1);
                          }}
                        >
                          Cancelled
                        </Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                  {!loading1 ? (
                    <>
                      <Card className="rounded-xl my-4">
                        <div className="overflow-x-auto overflow-y-scroll h-[50vh] scrollbar-always-visible">
                          <Table hoverable>
                            <Table.Head>
                              <Table.HeadCell>Requested By</Table.HeadCell>
                              <Table.HeadCell>Purpose</Table.HeadCell>
                              <Table.HeadCell>Status</Table.HeadCell>
                              <Table.HeadCell>Total</Table.HeadCell>
                              <Table.HeadCell>
                                <span className="sr-only">Edit</span>
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {vouchers.length === 0 ? (
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                  <Table.Cell
                                    colSpan={5}
                                    className="text-center py-10"
                                  >
                                    No {status} vouchers.
                                  </Table.Cell>
                                </Table.Row>
                              ) : (
                                vouchers.map((e, i) => (
                                  <Table.Row
                                    key={i}
                                    className={`bg-white dark:border-gray-700 dark:bg-gray-800 ${
                                      e.status === "approved"
                                        ? "read-msgs"
                                        : e.status === "cancelled"
                                        ? "reads-msgs"
                                        : ""
                                    }`}
                                  >
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white align-top">
                                      {e.requestedBy.firstName}{" "}
                                      {e.requestedBy.lastName}
                                    </Table.Cell>
                                    <Table.Cell className="text-ellipsis align-top">
                                      {e.item?.map((a, b) => {
                                        return (
                                          <>
                                            <div className="mb-1">
                                              <p className="capitalize">
                                                Payee: {a.payee}
                                              </p>
                                              <p className="capitalize">
                                                Bank: {a.bank}
                                              </p>
                                              <p className="capitalize">
                                                Account No.: {a.accountNumber}
                                              </p>
                                              <p className="capitalize">
                                                Amount:{" "}
                                                {a.amountProcessed.toLocaleString()}
                                              </p>
                                              <p className="capitalize">
                                                Payment Type: {a.paymentType}
                                              </p>
                                            </div>
                                            <hr />
                                          </>
                                        );
                                      })}
                                    </Table.Cell>
                                    <Table.Cell className="text-ellipsis capitalize align-top">
                                      {e.status}
                                    </Table.Cell>
                                    <Table.Cell className="text-ellipsis align-top">
                                      &#8358; {e.total.toLocaleString()}
                                    </Table.Cell>
                                    <Table.Cell className="align-top">
                                      <p
                                        className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                        onClick={() => displayOnModal(e)}
                                        style={{
                                          textDecoration: "none",
                                          cursor: "pointer",
                                        }}
                                      >
                                        View
                                      </p>
                                    </Table.Cell>
                                  </Table.Row>
                                ))
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </Card>

                      {vouchers?.length > 0 && (
                        <div className="flex overflow-x-auto sm:justify-center mb-5">
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={onPageChange}
                            showIcons
                            previousLabel=""
                            nextLabel=""
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex justify-center items-center h-[100%]">
                      <Spinner
                        style={{ color: "#0078e7" }}
                        aria-label="info spinner example"
                        size="xl"
                      />
                    </div>
                  )}
                </div>
              )}

              {/* add items to vouchers */}
              {secondCard && (
                <div
                  className="col-span-5 md:col-span-4"
                  data-aos="slide-right"
                >
                  <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                    Vouchers
                  </h5>
                  <div className="flex flex-wrap gap-2 mt-5 justify-between">
                    <Button
                      onClick={() => {
                        setSecondCard(false);
                        setError("");
                      }}
                      className="bg-#6eb5ff"
                    >
                      <IoMdArrowBack className="me-2" />
                      Go Back
                    </Button>

                    <Button
                      className="bg-#6eb5ff"
                      onClick={() => createVoucher()}
                    >
                      Submit
                      <IoMdSend className="ms-2" />
                    </Button>
                  </div>

                  <p className="text-red-600 text-center my-3 font-bold">
                    {error}
                  </p>
                  <Card className="w-1/2 mx-auto">
                    <div className="flex max-w-md flex-col gap-4">
                      {/* item */}
                      <div>
                        <div className="mb-2">
                          <div className="mb-2 block">
                            <Label
                              htmlFor="expenditureId"
                              value="Expenditure"
                            />
                          </div>
                          <Select
                            value={invoiceBody.expenditureId}
                            onChange={(e) =>
                              setInvoiceBody({
                                ...invoiceBody,
                                expenditureId: e.target.value,
                              })
                            }
                            id="expenditureId"
                            required
                          >
                            <option value={""}>---</option>
                            {expenditures?.map((e, i) => {
                              return (
                                <option value={e.id}>
                                  {e.requestedBy.firstName}{" "}
                                  {e.requestedBy.lastName}, {e.type},{" "}
                                  {e.total.toLocaleString()}
                                </option>
                              );
                            })}
                          </Select>
                        </div>

                        <div className="mb-2">
                          <div className="mb-2 block">
                            <Label htmlFor="payee" value="Payee" />
                          </div>
                          <TextInput
                            id="payee"
                            type="text"
                            placeholder="Enter Payee"
                            value={invoiceBody.payee}
                            onChange={(e) =>
                              setInvoiceBody({
                                ...invoiceBody,
                                payee: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-4 mb-2">
                          <div className="col-span-1">
                            <div className="mb-2 block">
                              <Label htmlFor="Amount11" value="Amount" />
                            </div>
                            <TextInput
                              id="Amount11"
                              type="number"
                              placeholder="Enter Amount"
                              min={0}
                              value={invoiceBody.amountProcessed}
                              onChange={(e) =>
                                setInvoiceBody({
                                  ...invoiceBody,
                                  amountProcessed: parseInt(e.target.value),
                                })
                              }
                            />
                          </div>

                          <div className="col-span-1">
                            <div className="mb-2 block">
                              <Label
                                htmlFor="Account1"
                                value="Account Number"
                              />
                            </div>
                            <TextInput
                              id="Account1"
                              type="text"
                              placeholder="Enter Account Number"
                              min={0}
                              value={invoiceBody.accountNumber}
                              onChange={(e) =>
                                setInvoiceBody({
                                  ...invoiceBody,
                                  accountNumber: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4 mb-2">
                          <div className="col-span-1">
                            <div className="mb-2 block">
                              <Label htmlFor="Bank1" value="Bank" />
                            </div>
                            <TextInput
                              id="Bank1"
                              type="text"
                              placeholder="Enter Bank Name"
                              min={0}
                              value={invoiceBody.bank}
                              onChange={(e) =>
                                setInvoiceBody({
                                  ...invoiceBody,
                                  bank: e.target.value,
                                })
                              }
                            />
                          </div>

                          <div className="col-span-1">
                            <div className="mb-2 block">
                              <Label
                                htmlFor="paymentType"
                                value="Payment Type"
                              />
                            </div>
                            <Select
                              value={invoiceBody.paymentType}
                              onChange={(e) =>
                                setInvoiceBody({
                                  ...invoiceBody,
                                  paymentType: e.target.value,
                                })
                              }
                              id="paymentType"
                              required
                            >
                              <option value={""}>---</option>
                              <option value={"transfer"}>Transfer</option>
                              <option value={"cash"}>Cash</option>
                              <option value={"pos"}>POS</option>
                            </Select>
                          </div>
                        </div>
                      </div>

                      <Button
                        type="button"
                        onClick={() => pushToInvoice(invoiceBody)}
                        className="bg-#6eb5ff"
                      >
                        Add to List
                      </Button>
                    </div>
                  </Card>
                </div>
              )}
            </div>
          </div>
        {/* )} */}

        {/* {!loading && ( */}
          <>
            {secondCard && (
              <>
                <div className="flex flex-wrap gap-2 mt-5 justify-end">
                  <Button
                    onClick={() => {
                      setVoucherArray({ rows: [] });
                      setError("");
                    }}
                    className="bg-#6eb5ff"
                  >
                    Clear
                    <AiOutlineClear className="ms-2" />
                  </Button>
                </div>
                <Card className="rounded-xl my-4">
                  <div className="">
                    <div className="overflow-x-auto">
                      <Table className="border-2 border-black text-xs">
                        <Table.Head>
                          <Table.HeadCell colSpan={4}>
                            Expenditure ID
                          </Table.HeadCell>
                          <Table.HeadCell colSpan={1}>Amount</Table.HeadCell>
                          <Table.HeadCell colSpan={1}>
                            Payment Type
                          </Table.HeadCell>
                          <Table.HeadCell colSpan={1}>Payee</Table.HeadCell>
                        </Table.Head>
                        {voucherArray?.rows?.map((e, i) => {
                          return (
                            <Table.Body className="divide-y divide-x text-black">
                              <Table.Row>
                                <Table.Cell
                                  className="whitespace-nowrap text-ellipsis"
                                  colSpan={4}
                                >
                                  {e.expenditureId}
                                </Table.Cell>
                                <Table.Cell colSpan={1}>
                                  {e.amountProcessed.toLocaleString()}
                                </Table.Cell>
                                <Table.Cell className="capitalize" colSpan={1}>
                                  {e.paymentType}
                                </Table.Cell>
                                <Table.Cell colSpan={1}>{e.payee}</Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          );
                        })}
                      </Table>
                    </div>
                  </div>
                </Card>
              </>
            )}
          </>
        {/* )} */}

        {/* modal for singleVoucher */}
        <Modal
          dismissible
          show={openModal}
          size="md"
          onClose={onCloseModal}
          popup
        >
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-6">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                <strong>Requested By: </strong>
                {singleVoucher?.requestedBy?.firstName}{" "}
                {singleVoucher?.requestedBy?.lastName}
              </h3>
              <div>
                <h4>
                  <strong>Total: &#8358; </strong>
                  {singleVoucher?.total?.toLocaleString()}
                </h4>
              </div>
              <div>
                <h4>
                  <strong>Date Created: </strong>
                  {formatDate(singleVoucher?.createdAt)}
                </h4>
              </div>
              <div>
                <h4 className="capitalize">
                  <strong>Status: </strong> {singleVoucher?.status}
                </h4>
              </div>
              <div>
                <h4 className="capitalize">
                  <strong>Items: </strong>{" "}
                  <ol
                    className="p-2 mb-2"
                    style={{
                      listStyleType: "inherit",
                      background: "",
                    }}
                  >
                    {singleVoucher?.item?.map((a, b) => (
                      <li className="bg-[whitesmoke] mb-3 p-2 rounded-md" key={b}>
                        <p>Payee: {a.payee}</p>
                        <p>
                          Amount: &#8358; {a.amountProcessed.toLocaleString()}
                        </p>
                        <p>Payment Type: {a.paymentType}</p>
                        {a.accountNumber && (
                          <p>Account Number: {a.accountNumber}</p>
                        )}
                        {a.bank && <p>Bank Name: {a.bank}</p>}
                      </li>
                    ))}
                  </ol>
                </h4>
              </div>
              {singleVoucher?.authorizedBy && (
                <div>
                  <h4>
                    <strong>Authorized By: </strong>
                    {singleVoucher?.authorizedBy?.firstName}{" "}
                    {singleVoucher?.authorizedBy?.lastName}
                  </h4>
                </div>
              )}
              {singleVoucher?.authorizedBy && (
                <div>
                  <h4>
                    <strong>Authorized Date: </strong>
                    {formatDate(singleVoucher?.authorizedDate)}
                  </h4>
                </div>
              )}

              <div className="w-full flex justify-center gap-3">
                {singleVoucher?.status === "pending" && (
                  <Button
                    color="failure"
                    onClick={() =>
                      changeVoucherStatus(singleVoucher.id, "cancelled")
                    }
                    disabled={loading2}
                  >
                    Cancel
                  </Button>
                )}
                {singleVoucher?.status === "pending" && (
                  <Button
                    color="success"
                    onClick={() =>
                      changeVoucherStatus(singleVoucher.id, "approved")
                    }
                    disabled={loading2}
                  >
                    Approve
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* modal for logout */}
        <Modal show={openModalOut} size="md" popup>
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {msg}
              </h3>
              <div className="flex justify-center gap-4">
                <Button color="failure" onClick={() => logOut()}>
                  {"OK"}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Vouchers;

function formatDate(dateString) {
  const dateObj = new Date(dateString);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[dateObj.getMonth()];

  const desiredFormat = `${dateObj.getFullYear()}-${month}-${dateObj.getDate()}`;

  return desiredFormat;
}
